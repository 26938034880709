<template>
  <div class="main-container ck-content">
    <template v-if="hasAccount">
      <van-swipe v-if="accountInfo" class="my-swipe" :autoplay="3000" :height="200" indicator-color="#e8b77e">
        <van-swipe-item v-for="(image, index) of accountInfo.pictures" :key="index">
          <img @click="preview(index)" class="image" v-lazy="image" :src="image">
        </van-swipe-item>
      </van-swipe>

      <div class="cell-group-title" v-if="accountInfo">
        <span class="title">详情</span>
        <span class="wechat-tips">微信{{ wechat }}</span>
        <span class="generate-intro" @click="showIntro">生成简图</span>
      </div>
      <div class="cell-group" v-if="accountInfo">
        <van-cell title="编号" :value="accountInfo.number" />
        <van-cell title="区号" :value="accountInfo.zone" />
        <van-cell title="登录天数" :value="accountInfo.openDays + '天'" />
        <van-cell title="VIP等级" :value="'V' + accountInfo.vipLevel" />
        <van-cell title="战力" :value="accountInfo.power | powerFormat" />
        <van-cell title="冰冻" :value="accountInfo.frozen | wanFormat" />
        <van-cell title="速度" :value="accountInfo.speed | wanFormat" />
        <van-cell title="法伤" :value="accountInfo.magicDamage | wanFormat" />
        <van-cell title="物伤" :value="accountInfo.physicalDamage | wanFormat" />
        <van-cell title="幸运" :value="accountInfo.lucky | wanFormat" />
        <van-cell title="渡劫" :value="accountInfo.catastropheLevel" />
        <van-cell title="是否榜一" :value="accountInfo.isRankOne ? '是' : '否'" />
<!--        <van-cell title="男/女号" :value="accountInfo.gender | genderFormat " />-->
        <van-cell title="金伙伴数量" :value="accountInfo.goldPartnerNum" />
        <van-cell center title="小号数量" label="250级以上" :value="accountInfo.trumpetNum" />
        <van-cell title="类型" :value="accountInfo.accountType | accountType" />
        <div class="van-cell account-remark">
          <div v-html="accountInfo.remark"></div>
        </div>
        <!--<van-cell title="备注" :value="accountInfo.remark" />-->
      </div>

      <account-intro-card v-if="accountInfo" :showIntroImg.sync="showIntroImg" :account-info="accountInfo"></account-intro-card>

      <van-submit-bar :decimal-length="0" currency="" :price="accountInfo && Number(accountInfo.price*100) || 0" label=" " button-text="联系管理"
                      button-color="linear-gradient(to left, #e8b77e, #f7d8b0)" @submit="toBuy">
        <div class="back-home" @click="toList"><van-icon name="wap-home" />返回主页</div>
        <template #tip>
          对这个不感兴趣, <span class="tips" @click="toList">看看其他</span>
        </template>
      </van-submit-bar>

      <van-popup round v-model="showBuy" position="bottom" :style="{ height: '80%' }">
        <div class="friends">
          <img :src="wechatImg" alt="二维码">
          <div class="right">
            <p>扫码加「二木大仙」微信</p>
            <p>或者添加微信号：{{ wechat }}</p>
            <p>发送编号即可开始交易</p>
          </div>
        </div>
        <!--      <h1 class="rule-title">交易规则</h1>-->
        <p class="rule-list">
          {{ buyRule }}
          <!--规则一：手续费为账号成交价的10%（封顶1000），由卖家承担，约好交易手续费减半（5%）。<br/><br/>-->

          <!--规则二：售出价为3000元以上的账号，请提供身份证正反面及不找回保证书。<br/><br/>-->

          <!--规则三：卖家需交100元押金，如果账号已在其他平台出售，需通知二木大仙撤下所发布内容并退回100元押金（如需延后过长时间换绑，则由买卖双方协商定押金金额）。<br/><br/>-->

          <!--规则四：如买家在交易过程中反悔，需与卖家沟通协商，如卖家同意则退还购号款项，但十分之一手续费二木大仙照常收取；如若卖家不同意，则交易照常进行，此处提醒各位以沟通协商为好。<br/><br/>-->

          <!--规则五：如若卖家在其他平台卖号价格比二木大仙这边的价格低，一经发现即撤下账号展示，押金不退回。<br/><br/>-->

          <!--规则六：如若买家在可换绑的时间一周内不提供手机号进行换绑，视为买家违约，不再提供交易保障，号款会在一周后转至卖家。<br/><br/>-->

          <!--规则七：如卖家在交易过程中反悔，则押金赔付给买家并终止交易。<br/><br/>-->

          <!--每次交易前会让买卖双方确认此规则。-->
        </p>
      </van-popup>
    </template>
    <template v-else>
      <van-empty image="search" description="已经出售">
        <van-button color="#db9e58" size="small" @click="toList" round class="bottom-button">看看其他</van-button>
      </van-empty>
    </template>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { Toast, Swipe, SwipeItem, Empty, Button, Lazyload, Icon, ImagePreview, Cell, SubmitBar, Popup } from 'vant';
  import { getAccountInfo, getSettings } from './../api/index';
  import AccountIntroCard from '../components/account-intro-card';

  Vue.use(Lazyload);

  export default {
    name: 'AccountInfo',
    props: [ 'id' ],
    components: {
      VanSwipe: Swipe,
      VanSwipeItem: SwipeItem,
      VanCell: Cell,
      VanSubmitBar: SubmitBar,
      VanPopup: Popup,
      VanIcon: Icon,
      VanEmpty: Empty,
      VanButton: Button,
      AccountIntroCard,
    },
    data() {
      return {
        buyRule: window.buyRule,
        wechat: window.wechat,
        wechatImg: window.wechatImg,
        showBuy: false,
        accountInfo: null,
        hasAccount: true,
        showIntroImg: false,
      }
    },
    async created() {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      if (!this.buyRule) {
        getSettings(['BuyRule']).then(res => {
          if (!res || !res.length) return false;
          if (res[0].text) {
            window.buyRule = this.buyRule = res[0].text;
          }
        });
      }

      try {
        const account = await getAccountInfo(this.id);
        if (!account) {
          return this.hasAccount = false;
        }
        this.accountInfo = account;
      } catch (e) {
        console.error(e);
        Toast.fail('加载失败');
      } finally {
        toast.clear();
      }
    },
    methods: {
      preview(index) {
        ImagePreview({
          images: this.accountInfo.pictures,
          startPosition: index,
        });
      },
      toList() {
        this.$router.replace({ name: 'trade' });
      },
      toBuy() {
        this.showBuy = true;
      },
      showIntro() {
        this.showIntroImg = true;
      }
    }
  }
</script>

<style scoped lang="scss">
  @keyframes streamer{
    100%{
      /* 背景位置 */
      background-position: -400% 0;
    }
  }

  .main-container {
    margin-bottom: 100px !important;
  }
  .cell-group-title {
    padding: 12px 16px 12px;
    color: #969799;
    font-size: 14px;
    line-height: 16px;
    .wechat-tips {
      padding-left: 6px;
      color: #db9e58;
    }
    .generate-intro {
      display: inline-block;
      float: right;
      background: #e8b981;
      color: #fff;
      padding: 0 12px;
      border-radius: 18px;
      margin-top: -5px;
      height: 26px;
      line-height: 26px;
      position: relative;
      background: linear-gradient(to left, rgb(255 204 140), rgb(223 121 0));
      //background: linear-gradient(to right, rgb(252, 74, 26), rgb(247, 183, 51));
      /* 背景渐变色大小 */
      background-size: 400%;
      animation: streamer 3s infinite;
    }
  }
  .van-cell {
    font-size: 12px;
    line-height: 22px;
  }
  .account-remark {
    display: block;
    ::v-deep p {
      margin: 0;
    }
  }
  .tips {
    color: #1989fa;
  }
  .image {
    width: 100%;
    height: 100%;
  }
  .friends {
    padding: 20px 20px 20px;
    display: flex;
    align-items: center;
    background: #e8b77e;
    img {
      width: 125px;
      height: 125px;
    }
    .right {
      margin-left: 15px;
      p {
        font-size: 14px;
        margin: 12px 0;
        font-weight: bold;
        color: #FFF;
      }
    }
  }
  .rule-title {
    font-size: 18px;
    margin: 15px;
    font-weight: normal;
  }
  .rule-list {
    font-size: 14px;
    margin: 15px;
    font-weight: normal;
    line-height: 26px;
    white-space: pre-wrap;
  }
  .back-home {
    display: flex;
    align-items: center;
    color: #1989fa;
    i {
      font-size: 16px;
      padding-right: 4px;
    }
  }
</style>
