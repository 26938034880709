import Vue from 'vue';

Vue.filter('powerFormat', function (val) {
  if (val > 100000000) {
    return parseFloat((val/100000000).toFixed(2)) + '亿';
  } else if (val > 10000) {
    return parseFloat((val/10000).toFixed(2)) + '万';
  } return val;
});

Vue.filter('genderFormat', function (val) {
  if (val === 'male') {
    return '男';
  } else if (val === 'female') {
    return '女';
  } else {
    return '-';
  }
});

Vue.filter('wanFormat', function (val) {
  if (val && val > 100000000) {
    return Number((val / 100000000).toFixed(2)) + '亿';
  } else if (val && val > 10000) {
    return Number((val / 10000).toFixed(2)) + '万';
  } else {
    return val
  }
});

Vue.filter('accountType', function (val) {
  if (val === 'mail') {
    return '邮箱';
  } else if (val === 'mobile') {
    return '手机';
  } else if (val === 'wechat') {
    return '微信';
  } else {
    return '-';
  }
});
