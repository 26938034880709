<template>
  <div>
    <div v-if="showTooltip" class="tooltip">点此关注抖音</div>
    <van-tabs ref="tabs" :before-change="beforeActiveChange" v-model="activeTab" color="#e8b77e" sticky>
      <van-tab title="百宝箱">
        <toolkit></toolkit>
      </van-tab>
      <van-tab title="关注抖音">
<!--        <van-sticky :container="tabsRef" :offset-top="44">-->
<!--          <van-search @focus="showSearchWold = true" @clear="search('')" @search="search(searchKeyWord)" v-model="searchKeyWord" placeholder="请输入搜索关键词" />-->
<!--        </van-sticky>-->
<!--        <div v-show="showSearchWold" class="hot-search-words" @click="showSearchWold = false">-->
<!--          <div class="content" @click.stop="() => {}">-->
<!--            <div class="tips">热门搜索</div>-->
<!--            <div class="tag" v-for="(word, index) of hotSearchWolds" :key="index" @click.stop="search(word)">{{ word }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <van-empty description="暂无攻略 或者 换个搜索词看看" v-if="!articles.length"></van-empty>-->
<!--        <van-pull-refresh v-else v-model="refreshing" @refresh="onRefresh">-->
<!--          <van-list-->
<!--            v-model="loading"-->
<!--            :finished="finished"-->
<!--            finished-text="没有更多了"-->
<!--            class="article-list"-->
<!--            @load="getArticles"-->
<!--          >-->
<!--            <div class="article-card" v-for="(article, index) of articles" :key="index" @click="toDetail(article)">-->
<!--              <div class="stick-icon" v-if="!!article.seq">置顶</div>-->
<!--              <img :src="article.cover" alt="封面">-->
<!--              <p class="title">{{ article.title }}</p>-->
<!--              <p class="desc" v-if="article.desc">{{ article.desc }}</p>-->
<!--            </div>-->
<!--          </van-list>-->
<!--        </van-pull-refresh>-->
      </van-tab>
      <!--<van-tab title="打赏榜">-->
        <!--<reward-rank-list></reward-rank-list>-->
      <!--</van-tab>-->
    </van-tabs>
  </div>
</template>

<script>
  import { Tab, Tabs } from 'vant';
  import { getArticles, getSearchWord } from './../api/index';
  // import RewardRankList from "../components/reward-rank-list";
  import Toolkit from "../components/toolkit/index";

  export default {
    name: 'ArticleList',
    components: {
      // RewardRankList,
      Toolkit,
      VanTabs: Tabs,
      VanTab: Tab,
      // VanList: List,
      // VanPullRefresh: PullRefresh,
      // VanSearch: Search,
      // VanSticky: Sticky,
      // VanEmpty: Empty,
    },
    data() {
      return {
        tabsRef: null,

        showSearchWold: false, // 是否显示热搜词
        hotSearchWolds: [], // 热搜词

        searchKeyWord: '', // 搜索关键词
        activeTab: '',
        refreshing: false, // 是否下拉刷新中
        loading: false, // 是否底部加载中
        finished: false, // 是否加载完所有数据
        skip: 0,
        articles: [],

        showTooltip: true, // 点击提示
      }
    },
    mounted() {
      this.tabsRef = this.$refs.tabs;

      setTimeout(() => {
        this.showTooltip = false;
      }, 5000);
    },
    created() {
      this.initData();
      this.getArticles();
      getSearchWord()
        .then(words => {
          this.hotSearchWolds = words.map(word => word.value);
        });
    },
    methods: {
      toDetail(article) {
        this.$router.push({ name: 'articleDetail', params: { id: article.id }});
      },
      onRefresh() {
        this.finished = false;
        this.skip = 0;
        this.refreshing = true;
        this.getArticles(true);
      },
      initData() {
        this.loading = false;
        this.finished = false;
        this.skip = 0;
        this.articles = [];
        this.refreshing = false;
      },
      async getArticles(refresh = false) {
        try {
          this.loading = true;
          const condition = { skip: this.skip };
          if (this.searchKeyWord) condition.keyword = this.searchKeyWord;
          const articles = await getArticles(condition);

          if (refresh) { // 下拉刷新
            this.refreshing = false;
            this.articles = [...articles];
          } else {
            this.articles.push(...(articles || []));
          }

          this.skip += articles.length;
          if (!articles || !articles.length) {
            this.finished = true;
          }
        } catch {
          this.finished = true;
        } finally {
          this.loading = false;
        }
      },
      search(word) {
        this.searchKeyWord = word;
        this.onRefresh();
        this.getArticles(true);
        this.showSearchWold = false;
      },
      beforeActiveChange(name) {
        if (name === 1) {
          window.location.href = 'https://v.douyin.com/iff1tdS1/ 8@1.com';
          return false;
        } else return true;
      }
    }
  }
</script>

<style scoped lang="scss">
  .article-card {
    margin: 20px;
    border-radius: 8px;
    background: #FFF;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
    .title {
      margin: 12px;
      font-size: 14px;
    }
    .desc {
      margin: -4px 12px 12px;
      font-size: 12px;
      color: #999;
      max-height: 34px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .stick-icon {
      position: absolute;
      right: 0;
      top: 0;
      background: #f7b467;
      width: 50px;
      height: 24px;
      display: flex;
      justify-content: center;
      color: #FFF;
      border-radius: 0 0 0 8px;
      font-size: 12px;
      line-height: 24px;
    }
  }
  .hot-search-words {
    position: fixed;
    bottom: 0;
    top: 98px;
    z-index: 100;
    background: RGBA(0,0,0,0.7);
    left: 0;
    right: 0;
    .content {
      background: #FFF;
      padding: 12px 20px 12px;
      border-radius: 0 0 10px 10px;
      .tips {
        color: #333;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 16px;
      }
      .tag {
        margin: 0 15px 13px 0;
        display: inline-block;
        background: #f9f9f9;
        border-radius: 4px;
        font-size: 14px;
        padding: 4px 8px;
        color: #797979;
      }
    }
  }

  .tooltip {
    position: absolute;
    z-index: 100;
    background: rgba(0,0,0,0.6);
    color: #FFFFFF;
    font-size: 14px;
    padding: 8px;
    border-radius: 6px;
    top: 57px;
    margin-left: calc(75vw - 48px);
    animation-duration: 0.5s;
    animation-name: tooltip-shake;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  .tooltip::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom: 5px solid rgba(0,0,0,0.6);
    position: absolute;
    top: -10px;
    left: 45px;
  }
  @keyframes tooltip-shake {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(10px);
    }
  }
</style>
