<template>
  <div class="ck-content">
    <van-popup class="overlay" v-model="showIntroImg" get-container="body" z-index="1000">
      <div class="intro-img-wrap" @click="hideIntroImg">
        <img class="intro-img" v-if="introImg" :src="introImg" @click.stop>
        <van-loading color="#e8b981" size="50px" text-size="16px" v-else-if="isLoadingIntro" vertical>正在生成简介图</van-loading>
        <div v-else class="error-tips">
          <van-icon name="warning" />
          简介图生成失败
        </div>
      </div>
    </van-popup>
    <!--隐藏，用于渲染图片-->
    <actual-intro :account-info="accountInfo" :currentQRcode="currentQRcode" :remark="remark" class="intro for-img" ref="intro"></actual-intro>
    <!--快速预览模式下展示-->
    <actual-intro :account-info="accountInfo" :currentQRcode="currentQRcode" :remark="remark" ref="showIntro" v-if="isQuickView"
                  class="intro quick-view"></actual-intro>
  </div>
</template>

<script>
  import { Popup, Loading, Icon, Toast } from 'vant';
  import QRCode from 'qrcode';
  import html2canvas from 'html2canvas';
  import ActualIntro from './actual-intro';

  export default {
    name: 'AccountCard',
    props: [ 'accountInfo', 'showIntroImg', 'isQuickView' ],
    components: {
      VanPopup: Popup,
      VanLoading: Loading,
      VanIcon: Icon,
      ActualIntro,
    },
    data() {
      return {
        wechat: window.wechat,
        currentQRcode: '', // 当前页面二维码
        isLoadingIntro: true, // 正在渲染图片
        introImg: '',
      }
    },
    watch: {
      accountInfo: {
        immediate: true,
        handler: async function() {
          let { href } = this.$router.resolve({ name: 'accountInfo' }, { params: { id: this.accountInfo.id } });
          await new Promise(resolve => {
            QRCode.toDataURL(location.protocol + '//' + location.host + href, {
              margin: 0,
              color: {
                dark:"#654c2cFF",
                light:"#f2ebe0FF"
              }
            }, (err, url) => {
              resolve();
              this.currentQRcode = url;
            });
          });

          if (!this.isQuickView) {
            this.$nextTick(this.generateImg);
          }
        }
      },
      showIntroImg: function() {
        if (this.showIntroImg) {
          Toast({
            message: '长按图片保存至本地',
            position: 'bottom',
          });
          if (!this.introImg) {
            this.generateImg();
          }
        }
      }
    },
    computed: {
      remark: function() {
        let remark = this.accountInfo.remark;
        let arr = remark.match(/<mark(.*?)>(.*?)<\/mark>/g);
        arr && arr.forEach(str => {
          let matches = str.match(/<mark(.*?)>(.*?)<\/mark>/);
          let content = matches[2].replace(/<[^>]+>/g,"").split('').map(a => `<span${matches[1]}>`+ a + '</span>').join('');
          remark = remark.replace(str, content);
        });
        return remark;
      }
    },
    methods: {
      hideIntroImg() {
        this.$emit('update:showIntroImg', false);
      },
      generateImg() {
        console.log( this.$refs.intro.$el.clientWidth);
        html2canvas(this.$refs.intro.$el, { width: this.$refs.intro.$el.clientWidth, scale: 4  }).then((canvas) => {
          let dataURL = canvas.toDataURL();
          this.isLoadingIntro = false;
          this.introImg = dataURL;
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .intro {
    position: absolute;
    width: 375px;
    left: -1000px;
    top: -1000px;
    background: #f2ebe0;
    color: #654c2c;
    padding: 15px 15px;
    line-height: 28px;
    overflow: hidden;

    &.quick-view {
      position: initial;
      left: 0;
      top: 0;
      width: calc(100vw - 20px);
    }
    &.for-img {
      font-weight: 900 !important;
    }
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      margin: 4px 0 18px;
    }
    .highlight {
      font-weight: bold;
      color: #ee0a24;
    }
    .row {
      display: flex;
      &>div {
        width: 50%;
      }
      ::v-deep p {
        margin: 0;
      }
      .qrcode-wrap {
        display: flex;
        align-items: center;
        .qrcode {
          width: 65px;
          height: 65px;
        }
        .right {
          margin-left: 6px;
          font-size: 12px;
          line-height: 18px;
          p {
            margin: 0;
            white-space: nowrap;
          }
          .wechat-tips {
            color: #f75f5f;
          }
        }
      }
    }
    .br {
      width: 100%;
      height: 2px;
      background: #654c2c;
      margin: 10px 0;
      overflow: hidden;
    }
    .watermark {
      position: absolute;
      color: #FFF;
      font-weight: bold;
      transform-origin: left;
      z-index: 100;
      text-shadow: 0 0 4px black;
      &.watermark1 {
        top: 165px;
        left: 210px;
        font-size: 20px;
        transform: rotate(315deg);
      }
      &.watermark2 {
        top: 360px;
        left: 60px;
        font-size: 24px;
        transform: rotate(45deg);
      }
    }
  }
  .van-loading {
    margin-top: 230px;
  }
  .error-tips {
    color: #ff6b6b;
    margin-top: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    i {
      font-size: 50px;
      margin-bottom: 20px;
    }
  }
  .overlay {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: inherit;
    background: transparent;
  }
  .intro-img-wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    .intro-img {
      margin-top: 10vh;
      max-width: 90%;
      max-height: 80%;
    }
  }
</style>
