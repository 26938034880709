<template>
  <div>
    <div class="title">二木大仙 - 展示号</div>
    <div class="row">
      <div>编号：{{ accountInfo.number }}</div>
      <div>渡劫：{{ accountInfo.catastropheLevel }}</div>
    </div>
    <div class="row">
      <div>区号：{{ accountInfo.zone }}</div>
      <div>金伙伴：{{ accountInfo.goldPartnerNum }}</div>
    </div>
    <div class="row">
      <div>战力：<span class="highlight">{{ accountInfo.power | powerFormat }}</span></div>
      <div>是否榜一：{{ accountInfo.isRankOne ? '是' : '否' }}</div>
    </div>
    <div class="row">
      <div><span class="highlight">{{ (accountInfo && Number(accountInfo.price) || 0) }}</span>米</div>
      <div>小号：{{ accountInfo.trumpetNum }}</div>
    </div>
    <div class="row">
<!--      <div>男/女号：{{ accountInfo.gender | genderFormat }}</div>-->
      <div>登录天数：{{ accountInfo.openDays + '天' }}</div>
      <div>类型：{{ accountInfo.accountType | accountType }}</div>
    </div>
    <div class="row">
      <div>VIP等级：{{ 'V' + accountInfo.vipLevel }}</div>
    </div>
    <div class="row">
      <div>
        <div>冰冻：<span class="highlight" style="color: #0a88ee;">{{ accountInfo.frozen }}</span></div>
        <div>速度：{{ accountInfo.speed | wanFormat }}</div>
        <div>法伤：{{ accountInfo.magicDamage | wanFormat }}</div>
        <div>物伤：{{ accountInfo.physicalDamage | wanFormat }}</div>
        <div>幸运：{{ accountInfo.lucky | wanFormat }}</div>
      </div>
      <div class="qrcode-wrap-container">
        <div :style="{color:dealCost.color}">{{dealCost.text}}</div>
        <div class="qrcode-wrap">
          <img class="qrcode" :src="currentQRcode">
          <div class="right">
            扫一扫<br/>可看图
          </div>
        </div>
<!--        <div class="right">-->
<!--          <p>需要找二木</p>-->
<!--          <p>微信：<span class="wechat-tips">{{ wechat }}</span></p>-->
<!--          <p>手xù封顶1000</p>-->
<!--          <p>约好手xù减半</p>-->
<!--        </div>-->
      </div>
    </div>
<!--    <p class="scan-tips">扫码可进入网站，查看详细信息</p>-->
    <div class="br"></div>
    <div class="watermark watermark1">二木微信：{{ wechat }}</div>
    <div ref="watermark2" class="watermark watermark2">二木微信：{{ wechat }}</div>
    <div ref="remark" v-html="remark">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AccountCard',
    props: [ 'accountInfo', 'currentQRcode', 'remark' ],
    data() {
      return {
        wechat: window.wechat,
        dealCost: window.dealCost,
      }
    },
    mounted() {
      this.setWatermark();
      window.addEventListener('resize', this.setWatermark);
    },
    unmounted() {
      window.removeEventListener('resize', this.setWatermark);
    },
    methods: {
      setWatermark() {
        this.watermark2 = this.$refs.watermark2;
        this.remarkRef = this.$refs.remark;
        if (this.remarkRef && this.watermark2) {
          for (let p of this.remarkRef.getElementsByTagName('p')) {
            if (p.innerText.includes('个金伙伴')) {
              p.style.display = 'inline-block';
              p.style.margin = '0 auto';
              this.watermark2.style.top = p.offsetTop + 'px';
              this.watermark2.style.left = (p.offsetLeft + p.offsetWidth) + 'px';
              break;
            }
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin: 4px 0 18px;
  }
  .highlight {
    font-weight: bold;
    color: #ee0a24;
  }
  .row {
    display: flex;
    &>div {
      width: 50%;
    }
    ::v-deep p {
      margin: 0;
    }
    .qrcode-wrap-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 10px;
      font-size: 15px;
      font-weight: bold;
      color: #ee0c24;
    }
    .qrcode-wrap {
      display: flex;
      align-items: center;

      .qrcode {
        width: 68px;
        height: 68px;
      }
      .right {
        margin-left: 6px;
        font-size: 12px;
        line-height: 18px;
        p {
          margin: 0;
          white-space: nowrap;
        }
        .wechat-tips {
          color: #f75f5f;
        }
      }
    }
  }
  .br {
    width: 100%;
    height: 2px;
    background: #654c2c;
    margin: 10px 0;
    overflow: hidden;
  }
  .watermark {
    position: absolute;
    color: rgba(255,255,255, 0.6);
    font-weight: bold;
    transform-origin: left;
    z-index: 100;
    &.watermark1 {
      top: 180px;
      left: 75%;
      font-size: 16px;
      transform: rotate(315deg);
    }
    &.watermark2 {
      top: 620px;
      left: 120px;
      font-size: 20px;
      //transform: rotate(45deg);
    }
  }
  .scan-tips {
    width: 50%;
    font-size: 12px;
    float: right;
    color: #ee0a24;
    margin: -8px 0 0 0;
    white-space: nowrap;
  }
</style>
